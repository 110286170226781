<template>
    <div>
    
        <b-row>
            <b-col offset-sm-2 sm="12" md="10" offset-lg="2" lg="8" align-self="center" class="mx-auto">
    
                <b-card class="pricing-basic-value font-weight-bolder text-primary" title="Purchase Meter 🚀">
                   
                   
                    <div class="text-center" v-if="load">
                        <b-spinner variant="primary" label="Text Centered" style="width: 3rem; height: 3rem;" class="mr-1" />
                    </div>
                    
                    <b-container v-else>
              <b-form    @submit.prevent="submit">

  <div class="d-flex align-items-center" v-if="selectedd">
    <strong> {{ selectedd.provider_name }}</strong>
      <b-avatar
      size="lg"
      class="ml-auto"
      :src="mainurl +  selectedd.provider_image"
    />
    </div>

               
    <div class="mb-2">
          <b-form-radio-group 
      v-model="selected"
      :options="options"
      value-field="item"
      text-field="name"
      disabled-field="notEnabled"
      class="demo-inline-spacing"
    />

  
    </div>
                 
      <b-form-group label="Select electricity provider" label-for="phone">
        <v-select
          v-model="selectedd"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="provider_name"
          placeholder="Please Select Provider"

          :options="optionsd"
          class="select-size-lg"
        />
      </b-form-group>

                  
    
                        <b-form-group label="Meter Number" label-for="phone">
                            <b-form-input v-model="number" id="phone" type="number" size="lg" placeholder="Meter Number" />
                        </b-form-group>
                      
    
    
                        <b-form-group label="Customer Name" label-for="name" v-if="verify">
                            <b-form-input v-model="name" id="name" size="lg" placeholder="Decoder Name" readonly />
                        </b-form-group>
    
       <b-form-group v-if="verify" label="Amount" label-for="amount">
                            <b-input-group prepend="₦" append>
                                <b-form-input  required type="number" min="100"  :placeholder="dataprice" v-model="amount" prepend="₦"  size="lg" />
                            </b-input-group>
    
                        </b-form-group>
    
                        <div class="demo-inline-spacing">
                            <b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="lg" block variant="primary" :disabled="process">
                                <b-spinner v-if="process"
          small
          type="grow"
        />  
                               
                  <span v-else> {{ verify == true ?  "Proceed" : "Continue"}}  </span>
                            </b-button>
                        </div>
      </b-form>
                    </b-container>
    
                </b-card>
            </b-col>
    
        </b-row>
    
    
    
    </div>
</template>

<script>
import { BCard, BInputGroup, BButtonGroup, BAvatar,  BFormRadioGroup, BFormRadio, BForm, BSpinner, BCardText, BButton, BFormInput, BImg, BFormGroup, BContainer, BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useServices from "@/auth/jwt/userServices"
export default {
    components: {
        BCard,
        BImg,
BButtonGroup,
BInputGroup,
vSelect,
BAvatar,
        BForm,
        BContainer,
        BSpinner,
        BFormRadio,
        ToastificationContent,
        BFormRadioGroup,
        BFormInput,
        BFormGroup,
        BButton,
        BAvatar,
        BCardText,
        BRow,
        BCol,

    },
    directives: {
        Ripple,
    },


    data() {
        return {
      selected: null,
      selectedd: null,
      optionsd: [],
      verify: false,
      amount: '',
      number: null,
      details: null,
      name : null,
      hiddenname: null,
      options: [
        { item: 'prepaid', name: 'PrePaid' },
        { item: 'postpaid', name: 'PostPaid' },
      ],
            load: false,
            process: false,
            mainurl: jwtDefaultConfig.mainurl,
         
           
        }
    },
 
    created() {
        this.getelect();
    },

    watch: {
        selected: function(value) {
            this.verify = false;
            this.name = null;
            this.details = null,
            this.amount = '';
        },

         selectedd: function(value) {
            this.verify = false;
            this.name = null;
            this.details = null,
             this.amount = '';
        }
    },

    methods: {


        submit() {

          if (this.verify == true) {
              this.processtransaction();
          } else {
              this.verifyelectricity();
          }



        },
        addstyle(id) {
            return this.selectedairtime == id ? "border: 0.3rem solid green" : "papa"
        },

        selectitem(id) {
            this.selectedairtime = id;
        },


        async getelect() {
            this.load = true;
            await useServices.getelectricity().then((result)=> {
                this.optionsd = result.data.data
                console.log(result.data.data)
            });

            this.load = false;

        },


         async verifyelectricity() {
    
  if ((this.selected != null) && (this.selectedd != null) && (this.number != null)) {
                this.process = true;
            console.log(this.number);
            try {
                await useServices.validateelectricity({
                    type: this.selected,
                    provider: this.selectedd.id,
                    meter: this.number,
                }).then((result) => {
 this.process = false;


              if (result.data.status == true) {
                  

                  if (result.data.data.name != false) {
                      this.verify = true;
                  this.name = result.data.data.name;
                  this.hiddenname = result.data.data.name;
                this.details = result.data.data.data;

                  } else {
                      
    this.$swal({
                            title: 'Error!',
                            text: "We cant verify your details , please make sure details are correct",
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })



                  }





                  
              } else {

                    this.$swal({
                            title: 'Error!',
                            text: result.data.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })

                  
              }



                });
            } catch (error) {
                 this.process = false;
                console.log(error.response.status)
                 if (error.response.status == 422) {
           
                 var data = JSON.parse(error.response.data.data)
          for (var k in data) {
        if (data.hasOwnProperty(k)) {

              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data[k][0],
                    },
                  })
          //  user[k] = data[k];
        }
    }
    



                }

            }

            this.process = false;

   
          } else {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: "Please Make Sure nothing is missing",
                    },
                  })
          }
       

        },





    
        async processtransaction() {
            var pram = {};
            try {
              this.process = true;
                await useServices.processelectricity({
                    name: this.hiddenname,
                    amount: this.amount,
                    meter: this.number,
                    type: this.selected,
                    provider: this.selectedd.id,
                }).then((result) => {
  this.process = false;

  console.log(result.data);
                 if (result.data.status == true) {
                        console.log(result.data.data.hash)

                        this.$router.push({name: "transaction", params: { hash: result.data.data.hash}});

                        console.log(result.data.data.hash)
                 } else {
                        this.$swal({
                            title: 'Error!',
                            text: result.data.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                 }
               


                });
            } catch (error) {
               this.process = false;
                console.log(error.response.status)
                 if (error.response.status == 422) {
           
                 var data = JSON.parse(error.response.data.data)
          for (var k in data) {
        if (data.hasOwnProperty(k)) {

              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data[k][0],
                    },
                  })
          //  user[k] = data[k];
        }
    }
    



                }

            }

            this.process = false;



        }
    },





}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
